a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@media (prefers-reduced-motion: reduce) {
  *,
  :after,
  :before {
    animation: none !important;
    transition: none !important;
  }
}

:root {
  --bg: #f7f8f9;
  --line: #5269bd;
  --fill: #9bdabe;
  --text: #1c494c;
  --subtext: #73cadc;
  --duo: #9bdabe;
}
.stripes {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.93 22.93'%3E%3Cpolygon fill='%239bdabe' points='0 8.18 14.75 22.93 22.74 22.93 0 0.19 0 8.18'/%3E%3Cpolygon fill='%239bdabe' points='22.93 8.37 22.93 0.38 22.56 0 14.56 0 22.93 8.37'/%3E%3C/svg%3E");
  background-size: 11px;
  opacity: 0.6;
}
.dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.9 22.9' style='enable-background:new 0 0 22.9 22.9;' xml:space='preserve'%3E%3Ccircle fill='%239bdabe' class='st0' cx='5.7' cy='5.9' r='3'/%3E%3Ccircle fill='%239bdabe' class='st0' cx='17.2' cy='17.2' r='3'/%3E%3C/svg%3E%0A");
  background-size: 12px;
}

a:not([class]) {
  font-weight: 700;
  color: inherit;
  border-bottom: 0.125em solid var(--fill);
}

a:not([class]),
a:not([class]) abbr {
  text-decoration: none;
}

.flow {
  margin: 0 auto;
  position: relative;
}

.flow > * + * {
  margin-top: 20px;
}

.flow em {
  font-style: italic;
}

.flow strong {
  font-weight: 700;
}

.flow label,
.flow p {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  margin: 25px auto;
  font-size: 1.6rem;
  line-height: 1.65;
  font-weight: 400;
  color: var(--text);
}

@media (min-width: 512px) {
  .flow label,
  .flow p {
    font-size: 1.8rem;
  }
}

.flow label:first-child,
.flow p:first-child {
  margin-top: 0;
}

.flow img {
  max-width: 100%;
  height: auto;
}

.flow > h3 + p {
  margin-top: 10px;
}

.flow label {
  font-weight: 700;
  display: block;
}

.flow * + h2 {
  margin-top: 90px;
}

.flow * + h3 {
  margin-top: 60px;
}

.flow ol,
.flow ul {
  list-style: none;
  padding-left: 2.5rem;
  margin: 30px auto;
}

.flow ol li,
.flow ul li {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.6rem;
  line-height: 1.65;
  font-weight: 400;
  color: var(--text);
  margin: 15px auto;
}

@media (min-width: 512px) {
  .flow ol li,
  .flow ul li {
    font-size: 1.8rem;
  }
}

.flow ul li {
  position: relative;
}

.flow ul li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: var(--subtext);
  left: -2.5rem;
  top: 0.85rem;
  border-radius: 100%;
}

.flow ol {
  counter-reset: list-counter;
}

.flow ol li {
  counter-increment: list-counter;
  position: relative;
}

.flow ol li:before {
  content: counter(list-counter) ".";
  position: absolute;
  font-weight: 700;
  vertical-align: bottom;
  width: 10px;
  height: 1em;
  color: var(--subtext);
  left: -2.5rem;
  top: 0;
}

.flow blockquote {
  position: relative;
  margin: 40px auto;
  padding-left: 40px;
}

.flow blockquote:before {
  content: "“";
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  position: absolute;
  top: -5px;
  left: 0;
  font-size: 8rem;
  font-weight: 700;
  color: var(--subtext);
}

.flow blockquote p {
  font-family: Bitter, serif;
  font-size: 2rem;
  letter-spacing: 0.0075em;
  line-height: 1.65;
  max-width: 900px;
  font-weight: 400;
  color: var(--text);
  font-weight: 700;
}

.flow blockquote p b {
  font-weight: 700;
}

@media (min-width: 512px) {
  .flow blockquote p {
    font-size: 2.4rem;
  }
}

@media (min-width: 512px) {
  .flow blockquote {
    margin: 60px auto;
    padding-left: 55px;
  }

  .flow blockquote:before {
    font-size: 10rem;
  }
}

.dot {
  color: var(--subtext);
}

.t-heading,
.t-strong,
b,
strong {
  font-weight: 700;
}

.t-heading,
.t-primary {
  position: relative;
  font-family: Bitter, serif;
  line-height: 1.125;
  color: var(--text);
  scroll-margin-top: -40px;
}

.t-allcaps {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.075em;
  line-height: 1.25;
}

.t-outline {
  text-shadow: -1px -1px var(--bg), 0 -1px var(--bg), 1px -1px var(--bg),
    -1px 0 var(--bg), 1px 0 var(--bg), -1px 1px var(--bg), 0 1px var(--bg),
    1px 1px var(--bg), -2px -2px var(--bg), 0 -2px var(--bg), 2px -2px var(--bg),
    -2px 0 var(--bg), 2px 0 var(--bg), -2px 2px var(--bg), 0 2px var(--bg),
    2px 2px var(--bg), -3px -3px var(--bg), 0 -3px var(--bg), 3px -3px var(--bg),
    -3px 0 var(--bg), 3px 0 var(--bg), -3px 3px var(--bg), 0 3px var(--bg),
    3px 3px var(--bg), -4px -4px var(--bg), 0 -4px var(--bg), 4px -4px var(--bg),
    -4px 0 var(--bg), 4px 0 var(--bg), -4px 4px var(--bg), 0 4px var(--bg),
    4px 4px var(--bg);
}

abbr,
dfn {
  display: inline-block;
  line-height: 1.25;
  border-bottom: 0.125em dashed var(--fill);
  cursor: help;
  text-decoration: none;
}

@media screen and (max-width: 512px) {
  .t-beast\:s {
    font-size: 8rem;
  }

  .t-biggest\:s {
    font-size: 7.5rem;
  }

  .t-bigger\:s {
    font-size: 6rem;
  }

  .t-big\:s {
    font-size: 4.2rem;
  }

  .t-medium\:s {
    font-size: 3.8rem;
  }

  .t-subhead\:s {
    font-size: 2.8rem;
  }

  .t-lede\:s {
    font-size: 2.4rem;
  }

  .t-sublede\:s {
    font-size: 2rem;
  }

  .t-body\:s {
    font-size: 1.8rem;
  }

  .t-small\:s {
    font-size: 1.5rem;
  }

  .t-tiny\:s {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 513px) and (max-width: 1000px) {
  .t-beast\:m {
    font-size: 8rem;
  }

  .t-biggest\:m {
    font-size: 7.5rem;
  }

  .t-bigger\:m {
    font-size: 6rem;
  }

  .t-big\:m {
    font-size: 4.2rem;
  }

  .t-medium\:m {
    font-size: 3.8rem;
  }

  .t-subhead\:m {
    font-size: 2.8rem;
  }

  .t-lede\:m {
    font-size: 2.4rem;
  }

  .t-sublede\:m {
    font-size: 2rem;
  }

  .t-body\:m {
    font-size: 1.8rem;
  }

  .t-small\:m {
    font-size: 1.5rem;
  }

  .t-tiny\:m {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1001px) {
  .t-beast\:l {
    font-size: 8rem;
  }

  .t-biggest\:l {
    font-size: 7.5rem;
  }

  .t-bigger\:l {
    font-size: 6rem;
  }

  .t-big\:l {
    font-size: 4.2rem;
  }

  .t-medium\:l {
    font-size: 3.8rem;
  }

  .t-subhead\:l {
    font-size: 2.8rem;
  }

  .t-lede\:l {
    font-size: 2.4rem;
  }

  .t-sublede\:l {
    font-size: 2rem;
  }

  .t-body\:l {
    font-size: 1.8rem;
  }

  .t-small\:l {
    font-size: 1.5rem;
  }

  .t-tiny\:l {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1201px) {
  .t-beast\:xl {
    font-size: 8rem;
  }

  .t-biggest\:xl {
    font-size: 7.5rem;
  }

  .t-bigger\:xl {
    font-size: 6rem;
  }

  .t-big\:xl {
    font-size: 4.2rem;
  }

  .t-medium\:xl {
    font-size: 3.8rem;
  }

  .t-lede\:xl {
    font-size: 2.4rem;
  }

  .t-sublede\:xl {
    font-size: 2rem;
  }

  .t-body\:xl {
    font-size: 1.8rem;
  }

  .t-small\:xl {
    font-size: 1.5rem;
  }

  .t-tiny\:xl {
    font-size: 1.2rem;
  }
}

.lh-tight {
  line-height: 1;
}

.lh-mid {
  line-height: 1.5;
}

.lh-loose,
p:not([class]) {
  line-height: 1.65;
}

p:not([class]) {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text);
}

@media (min-width: 512px) {
  p:not([class]) {
    font-size: 1.8rem;
  }
}

.article code {
  color: var(--subtext);
  font-weight: 700;
  white-space: nowrap;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 0.95em;
}

.article img + pre {
  margin-top: 25px;
}

.selectbox {
  margin-left: 1rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: var(--line);
  border: 3px solid var(--line);
  border-radius: 8px;
  overflow: hidden;
}

.selectbox:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 1rem;
  height: 0.6rem;
  margin-top: -0.3rem;
  border-top: 0.6rem solid var(--line);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
}

.selectbox select {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.6rem;
  line-height: 1.65;
  font-weight: 400;
  color: var(--text);
  background: transparent;
  appearance: none;
  margin: 0;
  border: none;
  padding: 0 3rem 0 1rem;
}

@media (min-width: 512px) {
  .selectbox select {
    font-size: 1.8rem;
  }
}

.avatar-wrap {
  perspective: 1000px;
  position: relative;
  width: 100%;
  height: 50vh;
}

.bar-outer {
  position: relative;
  height: 100%;
}

.bar {
  backface-visibility: hidden;
  transition: transform 1.5s ease-out;
  position: absolute;
  width: 80vw;
  max-width: 975px;
  height: 240px;
  background-size: 11px;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.75;
  overflow: hidden;
}

.bar:after,
.bar:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: var(--bg);
  animation: barReveal 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.5s backwards;
}

.is-loading .bar:after,
.is-loading .bar:before {
  animation-play-state: paused;
}

.bar:before {
  transform: translateX(-100%);
}

.bar:after {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.avatar {
  width: 60vw;
  height: auto;
  max-width: 465px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: bottom center;
  backface-visibility: hidden;
  will-change: transform;
}

.avwrap {
  position: relative;
  margin-bottom: 500px;
}

.beard,
.face-tilt,
.head__rotation,
.head__yaw,
.neck__shadow {
  transform-origin: 50% 80%;
}

.avatar--ready .beard,
.avatar--ready .ears__left,
.avatar--ready .ears__right,
.avatar--ready .face-tilt,
.avatar--ready .head__rotation,
.avatar--ready .head__yaw,
.avatar--ready .mouth {
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  transition: transform 0.35s ease-out;
}

.avatar--ready .neck__shadow {
  will-change: transform;
  backface-visibility: hidden;
  perspective: 1000;
  transition: transform 0.4s ease-in-out;
}

.avatar--ready .neck__adamsapple {
  will-change: transform;
  animation: breath 2.5s ease-in-out infinite alternate;
}

.avatar--ready .head__y {
  will-change: transform;
  animation: breathHead 2.5s ease-in-out infinite alternate;
}

.avatar--ready .nose__breath {
  will-change: transform;
  animation: breathNose 2.5s ease-in-out infinite alternate;
}

.avatar--ready .eyes__x,
.avatar--ready .eyes__y {
  backface-visibility: hidden;
  perspective: 1000;
  transition: all 0.1s ease-out 10ms;
}

.avatar--ready.creeper .eyes__x,
.avatar--ready.creeper .eyes__y {
  backface-visibility: hidden;
  perspective: 1000;
  transition: all 0.3s ease-in-out;
}

.avatar--ready .eyebrow {
  transition: all 0.36s ease-in-out;
}

.avatar--ready .eyebrow,
.avatar--ready .mouth path {
  backface-visibility: hidden;
  perspective: 1000;
}

.dizzy .mouth svg {
  display: none;
}

.dizzy .mouth:before {
  width: 12%;
  background: var(--line);
  bottom: 34%;
  transform-origin: top center;
  animation: breatheMouthUp 2s ease-in-out infinite alternate;
}

.dizzy .mouth:after,
.dizzy .mouth:before {
  content: "";
  position: absolute;
  height: 2%;
  border-radius: 100%;
  left: 50%;
  margin-left: -6%;
}

.dizzy .mouth:after {
  transform-origin: bottom center;
  width: 16%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--bg);
  bottom: 33%;
  width: 12%;
  animation: breatheMouth 2s ease-in-out infinite alternate;
}

.dizzy .eyebrows__left {
  animation: dizzyBrowL 2s ease-in-out infinite alternate;
}

.dizzy .eyebrows__right {
  animation: dizzyBrowR 2s ease-in-out infinite alternate;
}

.dizzy .eyes__left .eye__dot {
  animation: dizzyEyeL 2.5s ease-in infinite alternate;
}

.dizzy .eyes__right .eye__dot {
  animation: dizzyEyeR 2.5s ease-in infinite alternate-reverse;
}

.dizzy .eyes__x,
.dizzy .eyes__y {
  transform: none;
}

.dizzy .head__yaw {
  animation: dizzyYaw 2.5s ease-in-out infinite alternate;
}

.dizzy .head__rotation {
  animation: dizzySway 1.5s ease-in-out infinite alternate;
}

.bgmask {
  fill: var(--bg);
}

.mask--chin {
  clip-path: url(#clip--chin);
}

@media screen and (max-width: 512px) {
  .avatar .svg-stroke {
    stroke-width: 0.45vw;
  }
}

@media screen and (min-width: 513px) and (max-width: 1200px) {
  .avatar .svg-stroke {
    stroke-width: 2.5px;
  }
}

.mask--eyebrow-l {
  clip-path: url(#clip--eyebrow-l);
}

.mask--eyebrow-r {
  clip-path: url(#clip--eyebrow-r);
}

.svg-pattern {
  fill: url(#pattern--stubble);
}

.layer * {
  backface-visibility: hidden;
}

.layer div,
.layer svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.avatar--intro .avatar__animwrap {
  backface-visibility: hidden;
  transform-origin: center bottom;
  animation: bakedAvatar 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) 1.25s
    backwards;
}

.avatar--intro .eyebrows,
.avatar--intro .hair,
.avatar--intro .mouth,
.avatar--intro .nose,
.avatar--intro .shading {
  backface-visibility: hidden;
  animation: bakedSecondary 1.5s cubic-bezier(0.215, 0.61, 0.355, 1) 1.25s
    backwards;
}

.avatar--intro .head__y {
  transform-origin: center 75%;
  animation: headIn 0.975s cubic-bezier(0.215, 0.61, 0.355, 1) 1.25s backwards;
}

.avatar--intro .eyes__glasses {
  backface-visibility: hidden;
  transform-origin: center bottom;
  animation: bakedGlasses 1.5s ease-out 1.25s backwards;
}

.avatar__animwrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
  transform-origin: bottom center;
}

.is-loading .avatar__animwrap,
.is-loading .eyebrows,
.is-loading .hair,
.is-loading .head__y,
.is-loading .mouth,
.is-loading .nose,
.is-loading .shading {
  animation-play-state: paused !important;
}

@keyframes barReveal {
  0% {
    transform: translateX(0);
  }
}

@keyframes avatarIn {
  0% {
    opacity: 0;
  }
}

@keyframes headIn {
  5% {
    transform: scale(1.075, 0.85);
  }

  20% {
    transform: scale(0.8, 1.05);
  }

  50% {
    transform: scale(1.025, 0.9);
  }

  0%,
  to {
    transform: scale(1);
  }
}

@keyframes breatheMouthUp {
  0% {
    transform: translateY(2px) scale(0.9);
  }

  to {
    transform: translateY(0) scale(0.85, 1.25);
  }
}

@keyframes breatheMouth {
  to {
    transform: scaleY(0.5);
  }
}

@keyframes breath {
  to {
    transform: none;
  }

  0% {
    transform: translateY(2px);
  }
}

@keyframes breathHead {
  to {
    transform: translateY(4px);
  }

  0% {
    transform: translateY(0);
  }
}

@keyframes breathNose {
  to {
    transform: none;
  }

  0% {
    transform: translateX(-24px) scaleX(1.075);
  }
}

@keyframes dizzySway {
  0% {
    transform: rotate(-2deg);
  }

  to {
    transform: rotate(2deg);
  }
}

@keyframes dizzyEyeL {
  0% {
    transform: translate(68px, 85px) scale(0.75);
  }
}

@keyframes dizzyEyeR {
  0% {
    transform: translate(108px, 85px) scale(0.75);
  }
}

@keyframes dizzyYaw {
  0% {
    transform: translateY(-2px);
  }

  to {
    transform: translateY(5px);
  }
}

@keyframes dizzyBrowL {
  0% {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(5px);
  }
}

@keyframes dizzyBrowR {
  0% {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes bakedAvatar {
  0% {
    opacity: 0;
    transform: scale(1.2337282615, 0.9459515515);
  }

  1% {
    transform: scale(1.2918607173, 1.0306209681);
  }

  3% {
    transform: scale(1.3225012657, 1.1003424939);
  }

  4% {
    transform: scale(1.3279217986, 1.1529759485);
  }

  6% {
    transform: scale(1.2780508182, 1.2053248387);
  }

  7% {
    transform: scale(1.2319227548, 1.2071001777);
  }

  9% {
    transform: scale(1.1212535786, 1.1731169371);
  }

  10% {
    transform: scale(1.065401853, 1.1432184169);
  }

  12% {
    transform: scale(1.0139650316, 1.1087997909);
  }

  14% {
    transform: scale(0.9695373498, 1.0727445662);
  }

  15% {
    transform: scale(0.933868851, 1.0375976325);
  }

  17% {
    transform: scale(0.9078836758, 1.0054553618);
  }

  18% {
    transform: scale(0.8917480812, 0.9779011157);
  }

  20% {
    transform: scale(0.8849762281, 0.9559832879);
  }

  21% {
    transform: scale(0.8865612095, 0.9402307497);
  }

  23% {
    transform: scale(0.8951191068, 0.9306989734);
  }

  25% {
    transform: scale(0.909034912, 0.9270391878);
  }

  26% {
    transform: scale(0.9266007455, 0.9285826319);
  }

  28% {
    transform: scale(0.94613876, 0.9344322277);
  }

  29% {
    transform: scale(0.9661032636, 0.9435547022);
  }

  31% {
    transform: scale(0.9851587564, 0.9548672291);
  }

  32% {
    transform: scale(1.0022326142, 0.9673139193);
  }

  34% {
    transform: scale(1.0165429605, 0.9799288545);
  }

  35% {
    transform: scale(1.0276037641, 0.9918837191);
  }

  37% {
    transform: scale(1.0352103339, 1.0025193646);
  }

  39% {
    transform: scale(1.0394091434, 1.0113617679);
  }

  40% {
    opacity: 1;
    transform: scale(1.0404563112, 1.0181237609);
  }

  42% {
    transform: scale(1.0387691198, 1.0226946098);
  }

  43% {
    transform: scale(1.034874726, 1.0251199696);
  }

  45% {
    transform: scale(1.0293597564, 1.0255749681);
  }

  46% {
    transform: scale(1.0228238592, 1.0243331865);
  }

  48% {
    transform: scale(1.0158395586, 1.0217341395);
  }

  50% {
    transform: scale(1.0089199995, 1.0181515542);
  }

  51% {
    transform: scale(1.0024954209, 1.0139643429);
  }

  53% {
    transform: scale(0.9968985163, 1.009531703);
  }

  54% {
    transform: scale(0.9923582464, 1.0051732943);
  }

  56% {
    transform: scale(0.9890012015, 1.0011549741);
  }

  57% {
    transform: scale(0.9868592659, 0.9976801467);
  }

  59% {
    transform: scale(0.9858821306, 0.994886412);
  }

  60% {
    transform: scale(0.9859531139, 0.9928469165);
  }

  62% {
    transform: scale(0.9869067741, 0.9915755959);
  }

  64% {
    transform: scale(0.9885469209, 0.9910353795);
  }

  65% {
    transform: scaleX(0.9906638165);
  }

  67% {
    transform: scaleX(0.9930495981);
  }

  68% {
    transform: scaleX(0.9955112128);
  }

  70% {
    transform: scaleX(0.9978804249);
  }

  71% {
    transform: scaleX(1.000020707);
  }

  73% {
    transform: scaleX(1.0018310571);
  }

  75% {
    transform: scaleX(1.0032469692);
  }

  76% {
    transform: scaleX(1.0042389329);
  }

  78% {
    transform: scaleX(1.004808938);
  }

  79% {
    transform: scaleX(1.0049855108);
  }

  81% {
    transform: scaleX(1.0048178258);
  }

  82% {
    transform: scaleX(1.0043694066);
  }

  84% {
    transform: scaleX(1.0037118824);
  }

  85% {
    transform: scaleX(1.0029191863);
  }

  87% {
    transform: scaleX(1.0020624986);
  }

  89% {
    transform: scaleX(1.0012061418);
  }

  90% {
    transform: scaleX(1.000404541);
  }

  92% {
    transform: scaleX(0.9997002821);
  }

  93% {
    transform: scaleX(0.9991232199);
  }

  95% {
    transform: scaleX(0.9986905339);
  }

  96% {
    transform: scaleX(0.9984075818);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bakedGlasses {
  0% {
    transform: translate(1.8252168953px, 79.7310864062px);
  }

  1% {
    transform: translate(3.2774357765px, 75.4413983327px);
  }

  3% {
    transform: translate(4.3396984375px, 67.9666001762px);
  }

  4% {
    transform: translate(5.017755689px, 58.1765685246px);
  }

  6% {
    transform: translate(5.3336033642px, 35.0111906855px);
  }

  7% {
    transform: translate(5.059784667px, 23.1422908208px);
  }

  9% {
    transform: translate(3.9229540015px, 1.8073616254px);
  }

  11% {
    transform: translate(2.3804793012px, -13.8144413047px);
  }

  12% {
    transform: translate(1.58649247px, -19.0126499763px);
  }

  14% {
    transform: translate(0.8335809956px, -22.4440411613px);
  }

  15% {
    transform: translate(0.1537990991px, -24.2050590992px);
  }

  17% {
    transform: translate(-0.429446493px, -24.4624457122px);
  }

  19% {
    transform: translate(-0.9012614032px, -23.4339517343px);
  }

  20% {
    transform: translate(-1.2547155021px, -21.369410516px);
  }

  22% {
    transform: translate(-1.4899731836px, -18.5330831103px);
  }

  23% {
    transform: translate(-1.61319571px, -15.1879789668px);
  }

  25% {
    transform: translate(-1.6353065485px, -11.5826477653px);
  }

  26% {
    transform: translate(-1.5707036404px, -7.940735931px);
  }

  28% {
    transform: translate(-1.4359923625px, -4.4534154419px);
  }

  30% {
    transform: translate(-1.2488005569px, -1.2746296768px);
  }

  31% {
    transform: translate(-1.0267234054px, 1.4810339968px);
  }

  33% {
    transform: translate(-0.7864319692px, 3.7381398059px);
  }

  34% {
    transform: translate(-0.5429656858px, 5.4582357679px);
  }

  36% {
    transform: translate(-0.3092166255px, 6.6361344008px);
  }

  38% {
    transform: translate(-0.0956023431px, 7.2950696601px);
  }

  39% {
    transform: translate(0.0900849648px, 7.4811493971px);
  }

  41% {
    transform: translate(0.2426723798px, 7.2574941498px);
  }

  42% {
    transform: translate(0.359467663px, 6.6984090572px);
  }

  44% {
    transform: translate(0.4400155464px, 5.8838807491px);
  }

  46% {
    transform: translate(0.4857774684px, 4.8946297013px);
  }

  47% {
    transform: translate(0.4997628263px, 3.8078848536px);
  }

  49% {
    transform: translate(0.4861379768px, 2.6939848771px);
  }

  50% {
    transform: translate(0.449836323px, 1.6138523362px);
  }

  52% {
    transform: translate(0.3961891863px, 0.6173354141px);
  }

  53% {
    transform: translate(0.3305930804px, -0.257631515px);
  }

  55% {
    transform: translate(0.258224749px, -0.9851314195px);
  }

  57% {
    transform: translate(0.1838111536px, -1.5507399648px);
  }

  58% {
    transform: translate(0.1114576939px, -1.9504958671px);
  }

  60% {
    transform: translate(0.0445344705px, -2.189496245px);
  }

  61% {
    transform: translate(-0.0143825251px, -2.2802461116px);
  }

  63% {
    transform: translate(-0.0635207317px, -2.2408838509px);
  }

  65% {
    transform: translate(-0.1018779729px, -2.0933921298px);
  }

  66% {
    transform: translate(-0.1291552348px, -1.8618876376px);
  }

  68% {
    transform: translate(-0.1456639387px, -1.5710646863px);
  }

  69% {
    transform: translate(-0.152216349px, -1.2448483488px);
  }

  71% {
    transform: translate(-0.15000729px, -0.9052935827px);
  }

  73% {
    transform: translate(-0.140494532px, -0.5717486641px);
  }

  74% {
    transform: translate(-0.1252841457px, -0.2602849714px);
  }

  76% {
    transform: translate(-0.1060259031px, 0.0166187238px);
  }

  77% {
    transform: translate(-0.0843225117px, 0.250160448px);
  }

  79% {
    transform: translate(-0.061655183px, 0.4350989584px);
  }

  80% {
    transform: translate(-0.0393268188px, 0.5694740768px);
  }

  82% {
    transform: translate(-0.0184230056px, 0.6542027925px);
  }

  84% {
    transform: translate(0.0002099298px, 0.6925907867px);
  }

  85% {
    transform: translate(0.0159713052px, 0.6897972537px);
  }

  87% {
    transform: translate(0.0284989718px, 0.6522874542px);
  }

  88% {
    transform: translate(0.0376511423px, 0.5873027802px);
  }

  90% {
    transform: translate(0.0434797803px, 0.5023726398px);
  }

  92% {
    transform: translate(0.0461982053px, 0.4048866123px);
  }

  93% {
    transform: translate(0.0461454495px, 0.3017394183px);
  }

  95% {
    transform: translate(0.0437496824px, 0.1990556133px);
  }

  96% {
    transform: translate(0.0394927097px, 0.1019957906px);
  }

  98% {
    transform: translate(0.0338771901px, 0.0146416546px);
  }

  to {
    transform: translate(0);
  }
}

@keyframes bakedSecondary {
  0% {
    transform: translate(1.7959659753px, 31.0190756201px);
  }

  2% {
    transform: translate(4.1475565303px, 28.1896658639px);
  }

  5% {
    transform: translate(4.7346136551px, 25.0498718545px);
  }

  7% {
    transform: translate(4.9274862799px, 17.0267516236px);
  }

  10% {
    transform: translate(3.9455562973px, 7.0095785006px);
  }

  12% {
    transform: translate(2.6380651848px, 0.1856833204px);
  }

  15% {
    transform: translate(1.2872407443px, -4.4216480003px);
  }

  17% {
    transform: translate(0.1503062633px, -6.7146617386px);
  }

  20% {
    transform: translate(-0.638792475px, -7.0440477499px);
  }

  22% {
    transform: translate(-1.0528295751px, -5.998010865px);
  }

  25% {
    transform: translate(-1.1410079869px, -4.218173871px);
  }

  27% {
    transform: translate(-1.090744538px, -3.2342576839px);
  }

  30% {
    transform: translate(-0.9949108007px, -2.2665736839px);
  }

  32% {
    transform: translate(-0.7180572499px, -0.5508241259px);
  }

  35% {
    transform: translate(-0.3424919458px, 0.8833091539px);
  }

  37% {
    transform: translate(-0.1987099315px, 1.2516638676px);
  }

  40% {
    transform: translate(-0.0710324798px, 1.4904886752px);
  }

  42% {
    transform: translate(0.0370591444px, 1.6112932512px);
  }

  45% {
    transform: translate(0.1236967049px, 1.6294174036px);
  }

  47% {
    transform: translate(0.1884262261px, 1.5625676653px);
  }

  50% {
    transform: translate(0.2319743572px, 1.4295005375px);
  }

  52% {
    transform: translate(0.2628321448px, 1.0384149282px);
  }

  55% {
    transform: translate(0.2261366623px, 0.5023708992px);
  }

  57% {
    transform: translate(0.196199522px, 0.2960350152px);
  }

  60% {
    transform: translate(0.1618536151px, 0.1122649899px);
  }

  62% {
    transform: translate(0.1256200912px, -0.0438171866px);
  }

  65% {
    transform: translate(0.0896576678px, -0.1694061848px);
  }

  67% {
    transform: translate(0.0557201126px, -0.2637339844px);
  }

  70% {
    transform: translate(0.0251417355px, -0.327739029px);
  }

  72% {
    transform: translate(-0.0011534602px, -0.3637098105px);
  }

  75% {
    transform: translate(-0.0390673802px, -0.365311407px);
  }

  77% {
    transform: translate(-0.0574544456px, -0.300686443px);
  }

  80% {
    transform: translate(-0.0594462276px, -0.2032932835px);
  }

  82% {
    transform: translate(-0.055841257px, -0.1514719342px);
  }

  85% {
    transform: translate(-0.050090331px, -0.101451597px);
  }

  87% {
    transform: translate(-0.0428708077px, -0.0554134487px);
  }

  90% {
    transform: translate(-0.034810871px, -0.0149435954px);
  }

  92% {
    transform: translate(-0.0264669528px, 0.0189385625px);
  }

  95% {
    transform: translate(-0.0183088451px, 0.0457286972px);
  }

  97% {
    transform: translate(-0.010711855px, 0.0653694583px);
  }

  to {
    transform: translate(0);
  }
}

.avatar-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.avatar-bg .bar {
  left: 65%;
  height: 240px;
  max-width: 840px;
}

.avatar-bg .avatar-wrap {
  height: 100%;
}

.avatar-bg .avatar {
  max-width: 575px;
  top: 45%;
  left: 70%;
}

@media screen and (min-width: 1001px) and (max-height: 600px) {
  .avatar-bg {
    min-height: 600px;
    height: 600px;
  }
}

@media screen and (max-width: 512px) {
  .avatar-bg {
    margin-top: 60px !important;
  }
}

@media screen and (max-width: 1000px) {
  .avatar-bg {
    position: relative;
    margin-top: 100px;
    left: 0;
    height: 45vh;
    min-height: 300px;
  }

  .avatar-bg .bar {
    left: 50%;
    top: 40%;
    height: 25vw;
    max-height: 200px;
  }

  .avatar-bg .avatar {
    top: 0;
    transform: translate(-50%);
    width: 75vw;
    height: 45vh;
    min-height: 300px;
    left: 50%;
    max-width: 400px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .avatar-bg {
    left: 0;
  }

  .avatar-bg .bar {
    left: 60%;
    max-width: 65vw;
    height: 200px;
    max-height: 240px;
  }

  .avatar-bg .avatar {
    max-width: 485px;
    width: 50vw;
    left: 70%;
  }
}

.u-vhide {
  overflow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  left: -900px;
}

@media screen and (max-width: 512px) {
  .u-hide\:s {
    display: none;
  }
}

@media screen and (min-width: 513px) and (max-width: 1000px) {
  .u-hide\:m {
    display: none;
  }
}

@media screen and (min-width: 1001px) {
  .u-hide\:l {
    display: none;
  }
}

*,
:after,
:before {
  box-sizing: border-box;
}

main:focus {
  outline: none;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

html:after,
html:before {
  content: "";
  will-change: top left;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  height: 100%;
  width: 20px;
  background: #fff;
  z-index: 21000;
}

html:before {
  left: 0;
}

html:after {
  right: 0;
}

body {
  background: var(--bg);
  min-height: calc(100vh - 40px);
  position: relative;
  padding: 20px;
}

body:after,
body:before {
  content: "";
  will-change: top left;
  backface-visibility: hidden;
  position: fixed;
  left: 0;
  width: 100%;
  height: 20px;
  background: #fff;
  z-index: 20000;
}

body:before {
  top: 0;
}

body:after {
  bottom: 0;
}

body.lock-scroll,
html.lock-scroll {
  overflow: hidden;
}

@media (max-width: 512px) {
  html:after,
  html:before {
    width: 10px;
  }

  body {
    padding: 10px;
  }

  body:after,
  body:before {
    height: 10px;
  }
}

.inner-body {
  max-width: 975px;
  margin: 0 auto;
}

.pagewrap {
  min-height: calc(100vh - 40px);
  position: relative;
  background: var(--bg);
  overflow: hidden;
  transition: background 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 511px) {
  .pagewrap:before {
    content: "";
    position: fixed;
    background: linear-gradient(180deg, var(--bg) 60%, hsla(0, 0%, 100%, 0));
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 999;
  }
}

.chunk {
  margin: 150px auto;
}

.chunk:first-child {
  margin-top: 0;
}

@media (min-width: 741px) {
  .break-out {
    width: 75vw;
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.page-body {
  margin: 100px 20px;
}

@media (min-width: 512px) {
  .page-body {
    margin: 230px 30px 100px;
  }
}

@media (min-width: 800px) {
  .page-body {
    margin: 230px 60px 100px;
  }
}

.page-body--header {
  margin: 0 20px 100px;
}

@media (min-width: 512px) {
  .page-body--header {
    margin: 0 60px 100px;
  }
}

hr {
  height: 1px;
  border: none;
  background: var(--text);
  opacity: 0.275;
  margin: 100px auto;
}

.section {
  max-width: 1200px;
  margin: 0 auto;
}

.article {
  max-width: 740px;
  margin: 0 auto;
}

.cluster,
.repel {
  display: flex;
  align-items: center;
  color: inherit;
}

.cluster + pre[class*="language-"],
.repel + pre[class*="language-"] {
  margin-top: 25px;
}

.repel {
  justify-content: space-between;
}

.cluster {
  justify-content: space-around;
}

.mainnav {
  z-index: 10000;
  display: inline-block;
  vertical-align: middle;
  position: fixed;
  width: 200px;
  top: 0;
  left: 35px;
}

@media (min-width: 512px) {
  .mainnav {
    top: 30px;
    left: 65px;
  }
}

.logo-wrap {
  position: relative;
  z-index: 10000;
  display: inline-block;
  vertical-align: middle;
  max-width: 65px;
  padding-top: 8px;
  width: 100%;
}

@media (min-width: 512px) {
  .logo-wrap {
    padding-top: 0;
    max-width: 100px;
  }
}

.logo-wrap .logo {
  position: relative;
  display: block;
  animation: logoIn 0.45s ease-out 0.2s backwards;
}

.logo-wrap .logo .shade {
  transition: transform 1.5s ease-out;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.logo-wrap svg {
  max-width: 100%;
  height: auto;
  margin-left: -10px;
}

.svg-stroke {
  fill: none;
  stroke: var(--line);
  stroke-width: 2.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  vector-effect: non-scaling-stroke;
}

@media (min-width: 512px) {
  .svg-stroke {
    stroke-width: 3px;
  }
}

.svg-fill-stroke {
  fill: var(--line);
}

.svg-fill {
  fill: var(--fill);
}

.svg-blank {
  fill: none;
}

.stroke-path,
.stroke-poly {
  fill: none;
  stroke: var(--line);
  stroke-width: 2.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  vector-effect: non-scaling-stroke;
}

@media (min-width: 512px) {
  .stroke-path,
  .stroke-poly {
    stroke-width: 3px;
  }
}

.svg-stroke-thin {
  fill: none;
  stroke: var(--line);
  stroke-width: 2.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  vector-effect: non-scaling-stroke;
}

.svg-stroke-dashed {
  stroke-dasharray: 6;
}

.svg-bg {
  fill: var(--bg);
}

.filled-path,
.filled-poly,
.svg-fill {
  fill: var(--fill);
}

.hire-me {
  vertical-align: middle;
  text-align: center;
  width: 100px;
  height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  position: fixed;
  right: 65px;
  top: 80px;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text);
  outline: none;
  transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 90000;
}

.hire-me:hover {
  letter-spacing: 6px;
  color: var(--text);
}

.hire-me .shade {
  transition: transform 1.5s ease-out;
}

.hire-me .main svg,
.hire-me .shade {
  position: absolute;
  left: -35px;
  top: 40%;
  transform: translateY(-50%);
  width: 35px;
  height: 35px;
}

.hire-me .main svg .stroke-path,
.hire-me .shade .stroke-path {
  stroke-width: 2.5px;
}

@media (max-width: 511px) {
  .hire-me {
    right: 20px;
    top: 38px;
  }

  .hire-me svg {
    display: none;
  }
}

.btn {
  margin: 30px 0;
  font-size: 1.8rem;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  text-align: center;
}

.btn,
.btn__label {
  display: inline-block;
  position: relative;
}

.btn__label {
  padding: 15px 45px;
  color: var(--line);
  border: 3px solid var(--line);
  border-radius: 8px;
  z-index: 2;
}

.btn__fill {
  will-change: transform;
  backface-visibility: hidden;
  user-select: none;
  transition: transform 1.5s ease-out, background 0.15s ease-out;
  display: inline-block;
  position: absolute;
  padding: 16px 0;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  color: var(--bg);
  text-align: center;
  z-index: 1;
  border-radius: 8px;
  opacity: 0.75;
}

.btn:hover .btn__fill {
  background-color: var(--fill);
}

.btn--small {
  padding: 10px 0;
}

.clock {
  width: 7.5%;
  height: 7.5%;
  top: 70%;
  left: 67%;
}

.clock,
.clock:after {
  position: absolute;
  border-radius: 100%;
}

.clock:after {
  content: "";
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  background: var(--line);
  margin-left: -1px;
  margin-top: -1px;
}

.clock-pivot,
.clock-pivot__hand {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.1s ease-out;
}

.clock-pivot__hand--hour:after {
  height: 33.33%;
}

.clock-pivot__hand--hour:after,
.clock-pivot__hand--minute:after {
  content: "";
  position: absolute;
  width: 2px;
  left: 50%;
  margin-left: -1px;
  background: var(--line);
  bottom: 50%;
}

.clock-pivot__hand--minute:after {
  height: 49%;
}

.text {
  position: absolute;
  overflow: hidden;
}

.text--a {
  top: 23%;
  left: 49%;
  width: 44%;
  height: 12%;
}

.text--b {
  top: 47%;
  left: 6%;
  width: 36%;
  height: 12%;
}

.text--c {
  top: 67%;
  left: 60%;
  width: 36%;
  height: 10%;
}

.text__inr {
  position: absolute;
  height: 100%;
  width: 300vw;
  top: 0;
  left: 50%;
  background-size: auto;
  background-position: 0;
  background-repeat: repeat-x;
}

.text--b .text__inr {
  right: 200vw;
  left: auto;
  background-position: -50%;
}

.text--c .text__inr {
  background-position: -70%;
}

.made-in-wales {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.02em;
  color: #919795;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  margin-bottom: 20px;
}

@media (min-width: 1320px) {
  .made-in-wales {
    position: fixed;
    font-size: 1.2rem;
    transform-origin: bottom left;
    transform: rotate(-90deg);
    bottom: 84px;
    left: 55px;
    text-align: left;
  }

  .made-in-wales:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    height: 1px;
    width: 25px;
    background: #919795;
  }
}

.article-list {
  margin: 120px auto;
}

.article-listing {
  position: relative;
  padding-bottom: 30px;
}

.article-listing + .article-listing {
  margin-top: 50px;
}

.article-listing:not(:last-child):after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  border: none;
  background: var(--text);
  opacity: 0.275;
}

.article-listing__date {
  position: relative;
  display: block;
  color: var(--subtext);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.075em;
  line-height: 1.25;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

@media (min-width: 512px) {
  .article-listing__date {
    font-size: 1.75rem;
  }
}

.article-listing__link {
  text-decoration: none;
  color: inherit;
}

.article-listing__link:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1001px) {
  .article-listing__content {
    width: 60%;
    margin-right: 0;
  }

  .article-listing__date {
    position: absolute;
    top: 18px;
    left: 0;
    margin-right: 60px;
    white-space: nowrap;
  }

  .article-listing__date:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 10px;
    margin-top: -2px;
    width: 80px;
    height: 3px;
    background: var(--line);
  }
}

@keyframes lineIn {
  0% {
    opacity: 0;
    transform: translateX(500%) scaleX(5);
  }

  1% {
    opacity: 1;
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.article-heading {
  position: relative;
}

.article-heading .article-heading__byline,
.article-heading .article-heading__title > span {
  opacity: 0;
}

.article-heading .article-heading__title > span {
  transform: translateY(8px);
}

.article-heading .article-heading__title:before {
  opacity: 0;
}

html:not(.is-loading) .article-heading.is-active .article-heading__byline,
html:not(.is-loading) .article-heading.is-active .article-heading__title > span,
html:not(.is-loading) .is-onscreen .article-heading .article-heading__byline,
html:not(.is-loading)
  .is-onscreen
  .article-heading
  .article-heading__title
  > span {
  opacity: 1;
  transform: none;
}

html:not(.is-loading) .article-heading.is-active .article-heading__title:before,
html:not(.is-loading)
  .is-onscreen
  .article-heading
  .article-heading__title:before {
  animation: lineIn 0.25s cubic-bezier(0.45, 0.85, 0.35, 0.85) 0.3s both;
}

.transitions
  html:not(.is-loading)
  .article-heading.is-active
  .article-heading__title:before,
.transitions
  html:not(.is-loading)
  .is-onscreen
  .article-heading
  .article-heading__title:before {
  animation: lineIn 0.25s cubic-bezier(0.45, 0.85, 0.35, 0.85) both;
}

.article-heading__title {
  margin-bottom: 50px;
}

@media (min-width: 512px) {
  .article-heading__title {
    margin-bottom: 60px;
  }
}

@media (min-width: 750px) {
  .article-heading__title {
    margin-bottom: 60px;
  }
}

.article-heading__title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -23px;
  height: 3px;
  background: var(--subtext);
  width: 50px;
  transform-origin: left center;
}

@media (min-width: 512px) {
  .article-heading__title:before {
    width: 80px;
    bottom: -33px;
  }
}

@media (min-width: 750px) {
  .article-heading__title:before {
    bottom: -30px;
  }
}

.article-heading__title > span {
  display: block;
}

.article-heading__title > span,
.transitions .article-heading__title > span {
  transition: opacity 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s,
    transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
}

.article-heading--small .article-heading__title {
  margin-bottom: 20px;
}

@media (min-width: 512px) {
  .article-heading--small .article-heading__title {
    margin-bottom: 30px;
  }
}

@media (min-width: 750px) {
  .article-heading--small .article-heading__title {
    margin-bottom: 50px;
  }
}

.article-heading--small .article-heading__title:before {
  width: 30px;
  bottom: -15px;
}

@media (min-width: 512px) {
  .article-heading--small .article-heading__title:before {
    width: 50px;
    bottom: -20px;
  }
}

@media (min-width: 750px) {
  .article-heading--small .article-heading__title:before {
    bottom: -30px;
  }
}

@keyframes titleFade {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
}

.article-heading__byline {
  display: block;
  color: var(--subtext);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.075em;
  line-height: 1.25;
  margin-bottom: 10px;
  transition: opacity 0.35s cubic-bezier(0.215, 0.61, 0.355, 1) 0.75s,
    transform 0.35s cubic-bezier(0.215, 0.61, 0.355, 1) 0.75s;
}

@media (min-width: 512px) {
  .article-heading__byline {
    font-size: 1.75rem;
  }
}

.no-js .article-heading__byline,
.no-js .article-heading__title:before,
.no-js .article-heading__title > span,
.reduced-motion .article-heading__byline,
.reduced-motion .article-heading__title:before,
.reduced-motion .article-heading__title > span {
  opacity: 1;
  transition: none;
}

.no-js .article-heading__title > span,
.reduced-motion .article-heading__title > span {
  transform: none;
}

.hero {
  margin-top: 130px;
  position: relative;
  z-index: 1;
}

.hero__title {
  margin-bottom: 40px;
}

@media screen and (min-width: 513px) {
  .hero__title {
    text-align: center;
  }
}

.hero__content {
  max-width: 580px;
  margin: 0 auto;
}

.hero__bg {
  position: absolute;
  height: 100%;
  max-height: 450px;
  width: 50%;
  top: -45%;
  left: -40%;
  opacity: 0.8;
  transition: transform 1.5s ease-out;
  z-index: -1;
}

@media screen and (max-width: 512px) {
  .hero__bg {
    left: 25%;
    width: 75%;
    min-height: 130px;
    max-height: 380px;
    height: 25vw;
    top: -150px;
  }
}

@media screen and (min-width: 513px) and (max-width: 1000px) {
  .hero__bg {
    left: auto;
    right: -10%;
    width: 80%;
    min-height: 130px;
    max-height: 380px;
    height: 25vw;
    top: -150px;
  }
}

.portrait {
  margin-left: auto;
  position: relative;
  max-width: 320px;
  z-index: 1;
}

.portrait__bg {
  backface-visibility: hidden;
  transition: transform 1.5s ease-out;
  position: absolute;
  width: 95%;
  height: 95%;
  top: 8%;
  left: 8%;
  opacity: 0.75;
  z-index: 0;
}

.portrait__image {
  position: relative;
  display: inline-block;
  max-width: 500px;
  max-height: 500px;
  border-radius: 100%;
  overflow: hidden;
  border: 10px solid var(--bg);
  z-index: 1;
}

.portrait__image img {
  width: 100%;
  height: auto;
  display: block;
}

code[class*="language-"],
pre[class*="language-"] {
  color: #000;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"] {
  position: relative;
  margin: 0.5em 0;
  overflow: visible;
  padding: 0;
}

pre[class*="language-"] > code {
  position: relative;
  border-left: 10px solid #358ccb;
  box-shadow: -1px 0 0 0 #358ccb, 0 0 0 1px #dfdfdf;
  background-color: #fdfdfd;
  background-image: linear-gradient(
    transparent 50%,
    rgba(69, 142, 209, 0.04) 0
  );
  background-size: 3em 3em;
  background-origin: content-box;
  background-attachment: local;
}

code[class*="language"] {
  max-height: inherit;
  height: inherit;
  padding: 0 1em;
  display: block;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background-color: #fdfdfd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1em;
}

:not(pre) > code[class*="language-"] {
  position: relative;
  border-radius: 0.3em;
  color: #c92c2c;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline;
  white-space: normal;
}

pre[class*="language-"]:after,
pre[class*="language-"]:before {
  content: "";
  z-index: -2;
  display: block;
  position: absolute;
  bottom: 0.75em;
  left: 0.18em;
  width: 40%;
  height: 20%;
  max-height: 13em;
  box-shadow: 0 13px 8px #979797;
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  transform: rotate(-2deg);
}

:not(pre) > code[class*="language-"]:after,
pre[class*="language-"]:after {
  right: 0.75em;
  left: auto;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
}

.token.block-comment,
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
  color: #7d8b99;
}

.token.punctuation {
  color: #5f6364;
}

.token.boolean,
.token.constant,
.token.deleted,
.token.function-name,
.token.number,
.token.property,
.token.symbol,
.token.tag {
  color: #c92c2c;
}

.token.attr-name,
.token.builtin,
.token.char,
.token.function,
.token.inserted,
.token.selector,
.token.string {
  color: #2f9c0a;
}

.token.entity,
.token.operator,
.token.url,
.token.variable {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.class-name,
.token.keyword {
  color: #1990b8;
}

.token.important,
.token.regex {
  color: #e90;
}

.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.important {
  font-weight: 400;
}

.token.bold {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.namespace {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  pre[class*="language-"]:after,
  pre[class*="language-"]:before {
    bottom: 14px;
    box-shadow: none;
  }
}

.token.cr:before,
.token.lf:before,
.token.tab:not(:empty):before {
  color: #e0d7d1;
}

pre[class*="language-"].line-numbers.line-numbers {
  padding-left: 0;
}

pre[class*="language-"].line-numbers.line-numbers code {
  padding-left: 3.8em;
}

pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows {
  left: 0;
}

pre[class*="language-"][data-line] {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

pre[data-line] code {
  position: relative;
  padding-left: 4em;
}

pre .line-highlight {
  margin-top: 0;
}

:not(pre) > code[class*="language-"] {
  padding: 0.2em;
  color: var(--line);
  font-weight: 700;
  letter-spacing: 0.05em;
  background: transparent;
  border: none;
}

::selection {
  background: var(--line);
  color: var(--bg);
  text-shadow: none;
}

::-moz-selection {
  background: var(--line);
  color: #fff;
  text-shadow: none;
}

pre {
  margin: 40px auto;
  font-size: 1.6rem;
}

pre[class*="language-"]:after,
pre[class*="language-"]:before {
  content: none;
}

pre[class*="language-"] > code {
  box-shadow: none;
  border-left: 3px solid var(--line);
  padding: 1em;
}

.no-js .loading,
.no-js .loading__mask .no-js .mask-wave {
  display: none;
}

.loading {
  position: fixed;
  z-index: -11000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.loading__mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--fill);
  transform: translateY(-110%);
  backface-visibility: hidden;
}

.loading--in .loading__mask {
  transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.35s ease-out;
  transform: translateY(0);
}

.loading--out .loading__mask {
  transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translateY(110%);
}

.loading__mask .mask-wave {
  width: 200%;
  position: absolute;
  left: 0;
  white-space: nowrap;
  backface-visibility: hidden;
  font-size: 0;
  animation: wave 2s linear infinite;
}

.loading__mask .mask-wave svg {
  display: inline-block;
  width: 50%;
  height: auto;
}

.loading__mask .mask-wave.mask-wave--bottom {
  top: 100%;
}

.loading__mask .mask-wave.mask-wave--top {
  bottom: 100%;
}

.loading__mask .mask-wave.mask-wave--top svg {
  transform: scaleY(-1);
}

.loading--in,
.loading--out {
  z-index: 11000;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.loading--in .loader {
  opacity: 1;
  transition: opacity 0.2s ease-out 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

@keyframes fadeInSlide {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.reveal {
  backface-visibility: hidden;
  animation: fadeInSlide 0.75s ease-out 1.15s backwards;
}

.transitions .reveal {
  animation: fadeInSlide 0.75s ease-out 0.8s backwards;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--bg);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:first-child {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(19px);
  }
}

.menu-link {
  padding-top: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  z-index: 2;
}

.no-js .menu-link {
  display: none;
}

.menu-link:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 26px;
  margin-right: 16px;
  background: #919795;
  vertical-align: middle;
  animation: menuBarIn 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s backwards;
}

@keyframes logoIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menuBarIn {
  0% {
    transform: scaleY(0);
  }

  to {
    transform: none;
  }
}

@keyframes menuTextIn {
  0% {
    opacity: 0;
    transform: translatex(-10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.menu-link__trigger {
  vertical-align: middle;
  overflow: hidden;
  width: 70px;
  height: 18px;
  position: relative;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--text);
  outline: none;
  cursor: pointer;
  transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: menuTextIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.75s
    backwards;
}

.menu-link__trigger:hover {
  letter-spacing: 6px;
  color: var(--text);
}

.menu-link__mask {
  padding: 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.menu-link__label {
  display: inline-block;
  height: 20px;
  transform: translateY(-20px);
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.3);
}

.is-active .menu-link__label {
  transform: none;
}

.no-js .main-menu__mask {
  display: none;
}

.js .main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -10000;
}

.js .main-menu.is-open {
  z-index: 1000;
}

.main-menu__mask {
  position: fixed;
  transform-origin: bottom left;
  z-index: 10000;
  overflow: hidden;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  width: 100vw;
  height: 100vh;
  background: var(--fill);
  transform: translateY(-100%);
  transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.is-open .main-menu__mask {
  transform: none;
}

.js .main-menu__flyout {
  position: fixed;
  backface-visibility: hidden;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 100px 35px 50px;
}

@media (min-width: 512px) {
  .js .main-menu__flyout {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 150px 55px 80px 65px;
  }
}

@media (min-width: 512px) and (min-height: 700px) {
  .js .main-menu__flyout {
    padding: 180px 55px 80px 65px;
  }
}

.social-links {
  position: relative;
  margin: 0 0 30px;
  padding-left: 100px;
}

@media (min-width: 512px) and (min-height: 700px) {
  .social-links {
    margin: 0 0 50px;
    padding-left: 120px;
  }
}

.social-links:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  width: 80px;
  margin-top: -2px;
  background-color: var(--line);
}

.is-open .social-links:after {
  animation: menuLineIn 0.25s cubic-bezier(0.45, 0.85, 0.35, 0.85) both;
}

@keyframes menuLineIn {
  0% {
    opacity: 0;
    transform: translateX(500%) scaleX(5);
  }

  1% {
    opacity: 1;
  }

  to {
    transform: none;
  }
}

.social-links__item {
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0 !important;
}

@media (min-width: 512px) {
  .social-links__item {
    margin: 0 40px 0 0 !important;
  }
}

.social-links__link {
  vertical-align: middle;
  display: block;
  overflow: hidden;
  text-indent: -999px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 100%;
  opacity: 0;
}

.is-open .social-links__link {
  opacity: 1;
}

.social-links__link--tw {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTI0IDQuNTU3YTkuODMgOS44MyAwIDAxLTIuODI4Ljc3NSA0LjkzMiA0LjkzMiAwIDAwMi4xNjUtMi43MjQgOS44NjQgOS44NjQgMCAwMS0zLjEyNyAxLjE5NSA0LjkxNiA0LjkxNiAwIDAwLTMuNTk0LTEuNTU1Yy0zLjE3OSAwLTUuNTE1IDIuOTY2LTQuNzk3IDYuMDQ1QTEzLjk3OCAxMy45NzggMCAwMTEuNjcxIDMuMTQ5YTQuOTMgNC45MyAwIDAwMS41MjMgNi41NzQgNC45MDMgNC45MDMgMCAwMS0yLjIyOS0uNjE2Yy0uMDU0IDIuMjgxIDEuNTgxIDQuNDE1IDMuOTQ5IDQuODlhNC45MzUgNC45MzUgMCAwMS0yLjIyNC4wODQgNC45MjggNC45MjggMCAwMDQuNiAzLjQxOUE5LjkgOS45IDAgMDEwIDE5LjU0YTEzLjk0IDEzLjk0IDAgMDA3LjU0OCAyLjIxMmM5LjE0MiAwIDE0LjMwNy03LjcyMSAxMy45OTUtMTQuNjQ2QTEwLjAyNSAxMC4wMjUgMCAwMDI0IDQuNTU3eiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--tw {
  transition: opacity 0.5s ease-out 0.3s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575) 0.3s
    backwards;
}

.social-links__link--tw:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTI0IDQuNTU3YTkuODMgOS44MyAwIDAxLTIuODI4Ljc3NSA0LjkzMiA0LjkzMiAwIDAwMi4xNjUtMi43MjQgOS44NjQgOS44NjQgMCAwMS0zLjEyNyAxLjE5NSA0LjkxNiA0LjkxNiAwIDAwLTMuNTk0LTEuNTU1Yy0zLjE3OSAwLTUuNTE1IDIuOTY2LTQuNzk3IDYuMDQ1QTEzLjk3OCAxMy45NzggMCAwMTEuNjcxIDMuMTQ5YTQuOTMgNC45MyAwIDAwMS41MjMgNi41NzQgNC45MDMgNC45MDMgMCAwMS0yLjIyOS0uNjE2Yy0uMDU0IDIuMjgxIDEuNTgxIDQuNDE1IDMuOTQ5IDQuODlhNC45MzUgNC45MzUgMCAwMS0yLjIyNC4wODQgNC45MjggNC45MjggMCAwMDQuNiAzLjQxOUE5LjkgOS45IDAgMDEwIDE5LjU0YTEzLjk0IDEzLjk0IDAgMDA3LjU0OCAyLjIxMmM5LjE0MiAwIDE0LjMwNy03LjcyMSAxMy45OTUtMTQuNjQ2QTEwLjAyNSAxMC4wMjUgMCAwMDI0IDQuNTU3eiIvPjwvc3ZnPg==);
}

.social-links__link--ma {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-mastodon'%3E%3Cpath d='M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a3.614 3.614 0 01-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522c0-.859.22-1.541.66-2.046.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764.442.505.661 1.187.661 2.046v4.203z'/%3E%3C/svg%3E");
}

.is-open .social-links__link--ma {
  transition: opacity 0.5s ease-out 0.3s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575) 0.3s
    backwards;
}

.social-links__link--ma:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-mastodon'%3E%3Cpath d='M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a3.614 3.614 0 01-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522c0-.859.22-1.541.66-2.046.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764.442.505.661 1.187.661 2.046v4.203z'/%3E%3C/svg%3E");
}

.social-links__link--in {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDIuMTYzYzMuMjA0IDAgMy41ODQuMDEyIDQuODUuMDcgMy4yNTIuMTQ4IDQuNzcxIDEuNjkxIDQuOTE5IDQuOTE5LjA1OCAxLjI2NS4wNjkgMS42NDUuMDY5IDQuODQ5IDAgMy4yMDUtLjAxMiAzLjU4NC0uMDY5IDQuODQ5LS4xNDkgMy4yMjUtMS42NjQgNC43NzEtNC45MTkgNC45MTktMS4yNjYuMDU4LTEuNjQ0LjA3LTQuODUuMDctMy4yMDQgMC0zLjU4NC0uMDEyLTQuODQ5LS4wNy0zLjI2LS4xNDktNC43NzEtMS42OTktNC45MTktNC45Mi0uMDU4LTEuMjY1LS4wNy0xLjY0NC0uMDctNC44NDkgMC0zLjIwNC4wMTMtMy41ODMuMDctNC44NDkuMTQ5LTMuMjI3IDEuNjY0LTQuNzcxIDQuOTE5LTQuOTE5IDEuMjY2LS4wNTcgMS42NDUtLjA2OSA0Ljg0OS0uMDY5ek0xMiAwQzguNzQxIDAgOC4zMzMuMDE0IDcuMDUzLjA3MiAyLjY5NS4yNzIuMjczIDIuNjkuMDczIDcuMDUyLjAxNCA4LjMzMyAwIDguNzQxIDAgMTJjMCAzLjI1OS4wMTQgMy42NjguMDcyIDQuOTQ4LjIgNC4zNTggMi42MTggNi43OCA2Ljk4IDYuOThDOC4zMzMgMjMuOTg2IDguNzQxIDI0IDEyIDI0YzMuMjU5IDAgMy42NjgtLjAxNCA0Ljk0OC0uMDcyIDQuMzU0LS4yIDYuNzgyLTIuNjE4IDYuOTc5LTYuOTguMDU5LTEuMjguMDczLTEuNjg5LjA3My00Ljk0OCAwLTMuMjU5LS4wMTQtMy42NjctLjA3Mi00Ljk0Ny0uMTk2LTQuMzU0LTIuNjE3LTYuNzgtNi45NzktNi45OEMxNS42NjguMDE0IDE1LjI1OSAwIDEyIDB6bTAgNS44MzhhNi4xNjIgNi4xNjIgMCAxMDAgMTIuMzI0IDYuMTYyIDYuMTYyIDAgMDAwLTEyLjMyNHpNMTIgMTZhNCA0IDAgMTEwLTggNCA0IDAgMDEwIDh6bTYuNDA2LTExLjg0NWExLjQ0IDEuNDQgMCAxMDAgMi44ODEgMS40NCAxLjQ0IDAgMDAwLTIuODgxeiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--in {
  transition: opacity 0.5s ease-out 0.35s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575) 0.35s
    backwards;
}

.social-links__link--in:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEyIDIuMTYzYzMuMjA0IDAgMy41ODQuMDEyIDQuODUuMDcgMy4yNTIuMTQ4IDQuNzcxIDEuNjkxIDQuOTE5IDQuOTE5LjA1OCAxLjI2NS4wNjkgMS42NDUuMDY5IDQuODQ5IDAgMy4yMDUtLjAxMiAzLjU4NC0uMDY5IDQuODQ5LS4xNDkgMy4yMjUtMS42NjQgNC43NzEtNC45MTkgNC45MTktMS4yNjYuMDU4LTEuNjQ0LjA3LTQuODUuMDctMy4yMDQgMC0zLjU4NC0uMDEyLTQuODQ5LS4wNy0zLjI2LS4xNDktNC43NzEtMS42OTktNC45MTktNC45Mi0uMDU4LTEuMjY1LS4wNy0xLjY0NC0uMDctNC44NDkgMC0zLjIwNC4wMTMtMy41ODMuMDctNC44NDkuMTQ5LTMuMjI3IDEuNjY0LTQuNzcxIDQuOTE5LTQuOTE5IDEuMjY2LS4wNTcgMS42NDUtLjA2OSA0Ljg0OS0uMDY5ek0xMiAwQzguNzQxIDAgOC4zMzMuMDE0IDcuMDUzLjA3MiAyLjY5NS4yNzIuMjczIDIuNjkuMDczIDcuMDUyLjAxNCA4LjMzMyAwIDguNzQxIDAgMTJjMCAzLjI1OS4wMTQgMy42NjguMDcyIDQuOTQ4LjIgNC4zNTggMi42MTggNi43OCA2Ljk4IDYuOThDOC4zMzMgMjMuOTg2IDguNzQxIDI0IDEyIDI0YzMuMjU5IDAgMy42NjgtLjAxNCA0Ljk0OC0uMDcyIDQuMzU0LS4yIDYuNzgyLTIuNjE4IDYuOTc5LTYuOTguMDU5LTEuMjguMDczLTEuNjg5LjA3My00Ljk0OCAwLTMuMjU5LS4wMTQtMy42NjctLjA3Mi00Ljk0Ny0uMTk2LTQuMzU0LTIuNjE3LTYuNzgtNi45NzktNi45OEMxNS42NjguMDE0IDE1LjI1OSAwIDEyIDB6bTAgNS44MzhhNi4xNjIgNi4xNjIgMCAxMDAgMTIuMzI0IDYuMTYyIDYuMTYyIDAgMDAwLTEyLjMyNHpNMTIgMTZhNCA0IDAgMTEwLTggNCA0IDAgMDEwIDh6bTYuNDA2LTExLjg0NWExLjQ0IDEuNDQgMCAxMDAgMi44ODEgMS40NCAxLjQ0IDAgMDAwLTIuODgxeiIvPjwvc3ZnPg==);
}

.social-links__link--rss {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyIj48cGF0aCBkPSJNMi41ODEgNC43MDRjOS4zMzEgMCAxNi45MDcgNy41NzYgMTYuOTA3IDE2LjkwOHYuOTg5YTEuMTY0IDEuMTY0IDAgMDAxLjE2MiAxLjE2MmgxLjk3OWExLjE2IDEuMTYgMCAwMDEuMTYyLTEuMTYydi0uNjE1QzIzLjc5MSA5LjkwNSAxNC4yODcuNDAxIDIuNTgxLjQwMWgtLjk5QTEuMTYxIDEuMTYxIDAgMDAuNDMgMS41NjN2MS45OGExLjE2IDEuMTYgMCAwMDEuMTYxIDEuMTYxaC45OXptMCA4LjI4M2M0Ljc1MyAwIDguNjExIDMuODU5IDguNjExIDguNjEydi45ODlhMS4xNjIgMS4xNjIgMCAwMDEuMTYyIDEuMTYyaDEuOThhMS4xNiAxLjE2IDAgMDAxLjE2MS0xLjE2MnYtLjYxNWMuMDA5LTcuNTc0LTUuNzg2LTEzLjI4OC0xMi45MTQtMTMuMjg4aC0uOTlBMS4xNiAxLjE2IDAgMDAuNDMgOS44NDZ2MS45OGExLjE2IDEuMTYgMCAwMDEuMTYxIDEuMTYxaC45OXptMS4xMDQgMy41NDZhMy40OCAzLjQ4IDAgMDEzLjQ3OSAzLjQ3OSAzLjQ4IDMuNDggMCAwMS0zLjQ3OSAzLjQ3OSAzLjQ4IDMuNDggMCAwMS0zLjQ3OS0zLjQ3OSAzLjQ4IDMuNDggMCAwMTMuNDc5LTMuNDc5eiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--rss {
  transition: opacity 0.5s ease-out 0.35s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575) 0.35s
    backwards;
}

.social-links__link--rss:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMi41ODEgNC43MDRjOS4zMzEgMCAxNi45MDcgNy41NzYgMTYuOTA3IDE2LjkwOHYuOTg5YTEuMTY0IDEuMTY0IDAgMDAxLjE2MiAxLjE2MmgxLjk3OWExLjE2IDEuMTYgMCAwMDEuMTYyLTEuMTYydi0uNjE1QzIzLjc5MSA5LjkwNSAxNC4yODcuNDAxIDIuNTgxLjQwMWgtLjk5QTEuMTYxIDEuMTYxIDAgMDAuNDMgMS41NjN2MS45OGExLjE2IDEuMTYgMCAwMDEuMTYxIDEuMTYxaC45OXptMCA4LjI4M2M0Ljc1MyAwIDguNjExIDMuODU5IDguNjExIDguNjEydi45ODlhMS4xNjIgMS4xNjIgMCAwMDEuMTYyIDEuMTYyaDEuOThhMS4xNiAxLjE2IDAgMDAxLjE2MS0xLjE2MnYtLjYxNWMuMDA5LTcuNTc0LTUuNzg2LTEzLjI4OC0xMi45MTQtMTMuMjg4aC0uOTlBMS4xNiAxLjE2IDAgMDAuNDMgOS44NDZ2MS45OGExLjE2IDEuMTYgMCAwMDEuMTYxIDEuMTYxaC45OXptMS4xMDQgMy41NDZhMy40OCAzLjQ4IDAgMDEzLjQ3OSAzLjQ3OSAzLjQ4IDMuNDggMCAwMS0zLjQ3OSAzLjQ3OSAzLjQ4IDMuNDggMCAwMS0zLjQ3OS0zLjQ3OSAzLjQ4IDMuNDggMCAwMTMuNDc5LTMuNDc5eiIvPjwvc3ZnPg==);
}

.social-links__link--gh {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDBDNS4zNzQgMCAwIDUuMzczIDAgMTJjMCA1LjMwMiAzLjQzOCA5LjggOC4yMDcgMTEuMzg3LjU5OS4xMTEuNzkzLS4yNjEuNzkzLS41Nzd2LTIuMjM0Yy0zLjMzOC43MjYtNC4wMzMtMS40MTYtNC4wMzMtMS40MTYtLjU0Ni0xLjM4Ny0xLjMzMy0xLjc1Ni0xLjMzMy0xLjc1Ni0xLjA4OS0uNzQ1LjA4My0uNzI5LjA4My0uNzI5IDEuMjA1LjA4NCAxLjgzOSAxLjIzNyAxLjgzOSAxLjIzNyAxLjA3IDEuODM0IDIuODA3IDEuMzA0IDMuNDkyLjk5Ny4xMDctLjc3NS40MTgtMS4zMDUuNzYyLTEuNjA0LTIuNjY1LS4zMDUtNS40NjctMS4zMzQtNS40NjctNS45MzEgMC0xLjMxMS40NjktMi4zODEgMS4yMzYtMy4yMjEtLjEyNC0uMzAzLS41MzUtMS41MjQuMTE3LTMuMTc2IDAgMCAxLjAwOC0uMzIyIDMuMzAxIDEuMjNBMTEuNTA5IDExLjUwOSAwIDAxMTIgNS44MDNjMS4wMi4wMDUgMi4wNDcuMTM4IDMuMDA2LjQwNCAyLjI5MS0xLjU1MiAzLjI5Ny0xLjIzIDMuMjk3LTEuMjMuNjUzIDEuNjUzLjI0MiAyLjg3NC4xMTggMy4xNzYuNzcuODQgMS4yMzUgMS45MTEgMS4yMzUgMy4yMjEgMCA0LjYwOS0yLjgwNyA1LjYyNC01LjQ3OSA1LjkyMS40My4zNzIuODIzIDEuMTAyLjgyMyAyLjIyMnYzLjI5M2MwIC4zMTkuMTkyLjY5NC44MDEuNTc2QzIwLjU2NiAyMS43OTcgMjQgMTcuMyAyNCAxMmMwLTYuNjI3LTUuMzczLTEyLTEyLTEyeiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--gh {
  transition: opacity 0.5s ease-out 0.4s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.575) 0.4s
    backwards;
}

.social-links__link--gh:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEyIDBDNS4zNzQgMCAwIDUuMzczIDAgMTJjMCA1LjMwMiAzLjQzOCA5LjggOC4yMDcgMTEuMzg3LjU5OS4xMTEuNzkzLS4yNjEuNzkzLS41Nzd2LTIuMjM0Yy0zLjMzOC43MjYtNC4wMzMtMS40MTYtNC4wMzMtMS40MTYtLjU0Ni0xLjM4Ny0xLjMzMy0xLjc1Ni0xLjMzMy0xLjc1Ni0xLjA4OS0uNzQ1LjA4My0uNzI5LjA4My0uNzI5IDEuMjA1LjA4NCAxLjgzOSAxLjIzNyAxLjgzOSAxLjIzNyAxLjA3IDEuODM0IDIuODA3IDEuMzA0IDMuNDkyLjk5Ny4xMDctLjc3NS40MTgtMS4zMDUuNzYyLTEuNjA0LTIuNjY1LS4zMDUtNS40NjctMS4zMzQtNS40NjctNS45MzEgMC0xLjMxMS40NjktMi4zODEgMS4yMzYtMy4yMjEtLjEyNC0uMzAzLS41MzUtMS41MjQuMTE3LTMuMTc2IDAgMCAxLjAwOC0uMzIyIDMuMzAxIDEuMjNBMTEuNTA5IDExLjUwOSAwIDAxMTIgNS44MDNjMS4wMi4wMDUgMi4wNDcuMTM4IDMuMDA2LjQwNCAyLjI5MS0xLjU1MiAzLjI5Ny0xLjIzIDMuMjk3LTEuMjMuNjUzIDEuNjUzLjI0MiAyLjg3NC4xMTggMy4xNzYuNzcuODQgMS4yMzUgMS45MTEgMS4yMzUgMy4yMjEgMCA0LjYwOS0yLjgwNyA1LjYyNC01LjQ3OSA1LjkyMS40My4zNzIuODIzIDEuMTAyLjgyMyAyLjIyMnYzLjI5M2MwIC4zMTkuMTkyLjY5NC44MDEuNTc2QzIwLjU2NiAyMS43OTcgMjQgMTcuMyAyNCAxMmMwLTYuNjI3LTUuMzczLTEyLTEyLTEyeiIvPjwvc3ZnPg==);
}

.social-links__link--li {
  margin-top: -4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTQuOTggMy41QzQuOTggNC44ODEgMy44NyA2IDIuNSA2Uy4wMiA0Ljg4MS4wMiAzLjVDLjAyIDIuMTIgMS4xMyAxIDIuNSAxczIuNDggMS4xMiAyLjQ4IDIuNXpNNSA4SDB2MTZoNVY4em03Ljk4MiAwSDguMDE0djE2aDQuOTY5di04LjM5OWMwLTQuNjcgNi4wMjktNS4wNTIgNi4wMjkgMFYyNEgyNFYxMy44NjljMC03Ljg4LTguOTIyLTcuNTkzLTExLjAxOC0zLjcxNFY4eiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--li {
  transition: opacity 0.5s ease-out 0.45s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.5) 0.45s backwards;
}

.social-links__link--li:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTQuOTggMy41QzQuOTggNC44ODEgMy44NyA2IDIuNSA2Uy4wMiA0Ljg4MS4wMiAzLjVDLjAyIDIuMTIgMS4xMyAxIDIuNSAxczIuNDggMS4xMiAyLjQ4IDIuNXpNNSA4SDB2MTZoNVY4em03Ljk4MiAwSDguMDE0djE2aDQuOTY5di04LjM5OWMwLTQuNjcgNi4wMjktNS4wNTIgNi4wMjkgMFYyNEgyNFYxMy44NjljMC03Ljg4LTguOTIyLTcuNTkzLTExLjAxOC0zLjcxNFY4eiIvPjwvc3ZnPg==);
}

.social-links__link--email {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDEyLjcxM0wuMDE1IDNoMjMuOTcxTDEyIDEyLjcxM3ptLTUuNDI1LTEuODIyTDAgNS41NjJ2MTIuNTAxbDYuNTc1LTcuMTcyem0xMC44NSAwTDI0IDE4LjA2M1Y1LjU2MmwtNi41NzUgNS4zMjl6bS0xLjU1NyAxLjI2MUwxMiAxNS4yODdsLTMuODY4LTMuMTM1TC4wMjIgMjFoMjMuOTU2bC04LjExLTguODQ4eiIvPjwvc3ZnPg==);
}

.is-open .social-links__link--email {
  transition: opacity 0.5s ease-out 0.5s;
  animation: socialIn 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.75) 0.5s backwards;
}

.social-links__link--email:hover {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEyIDEyLjcxM0wuMDE1IDNoMjMuOTcxTDEyIDEyLjcxM3ptLTUuNDI1LTEuODIyTDAgNS41NjJ2MTIuNTAxbDYuNTc1LTcuMTcyem0xMC44NSAwTDI0IDE4LjA2M1Y1LjU2MmwtNi41NzUgNS4zMjl6bS0xLjU1NyAxLjI2MUwxMiAxNS4yODdsLTMuODY4LTMuMTM1TC4wMjIgMjFoMjMuOTU2bC04LjExLTguODQ4eiIvPjwvc3ZnPg==);
}

.primary-links__link {
  color: var(--text);
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-decoration: none;
  transition: color ease-out-quad 0.2s;
  font-size: 4rem;
  line-height: 5.2rem;
  width: 200px;
}

.primary-links__link:hover {
  color: var(--bg);
}

@media (min-width: 512px) and (min-height: 700px) {
  .primary-links__link {
    width: 360px;
    font-size: 6rem;
    line-height: 1.25;
  }
}

.primary-links__item {
  opacity: 0;
  transform: translateY(-5px);
}

.is-open .primary-links__item {
  opacity: 1;
  transform: none;
  transition: 0.7s ease-out;
}

.is-open .primary-links__item:first-child {
  transition-delay: 0.75s;
}

.is-open .primary-links__item:nth-child(2) {
  transition-delay: 1s;
}

.is-open .primary-links__item:nth-child(3) {
  transition-delay: 1.25s;
}

.is-open .primary-links__item:nth-child(4) {
  transition-delay: 1.5s;
}

.primary-links__summary {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  margin: 30px auto;
  font-size: 1.8rem;
  line-height: 1.45;
  font-weight: 400;
  color: var(--text);
}

@media (max-height: 700px) {
  .primary-links__summary {
    font-size: 1.6rem;
    line-height: 1.25;
  }
}

@media screen and (max-width: 1000px) {
  .primary-links__summary {
    display: none;
  }
}

.main-menu__inr,
.main-menu__links {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-menu__links {
  justify-content: space-between;
  max-height: 300px;
}

@media (min-height: 700px) {
  .main-menu__links {
    max-height: 460px;
  }
}

@keyframes socialIn {
  0% {
    transform: translateX(15px);
  }
}

.no-js .main-menu__flyout {
  position: fixed;
  z-index: 100000;
  top: 70px;
  left: 180px;
}

.no-js .main-menu__flyout:before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 26px;
  margin-right: 16px;
  background: #919795;
  vertical-align: middle;
  animation: menuBarIn 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s backwards;
}

.no-js .main-menu__inr {
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.no-js .social-links {
  display: none;
}

.no-js .main-menu__links {
  position: absolute;
  display: block;
  height: auto;
}

.no-js .primary-links__item {
  opacity: 1;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  transform: none;
}

.no-js .primary-links__summary {
  display: none;
}

.no-js .primary-links__link {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--text);
  outline: none;
  cursor: pointer;
  width: auto;
}

.duotone {
  display: block;
  position: relative;
  backface-visibility: hidden;
  filter: url(#duotone_filter);
}

.article__content {
  position: relative;
  background: var(--bg);
}

.js .reveal-content {
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.75s ease-out 0.85s, transform 0.75s ease-out 0.85s;
}

html.js.reduced-motion .reveal-content,
html:not(.is-loading).js .is-onscreen .reveal-content {
  opacity: 1;
  transform: none;
}

.panels--reversed .circle__bg {
  right: auto;
  left: -50px;
}

[data-drift],
[data-prlx] {
  backface-visibility: hidden;
}

[data-drift] {
  transition: transform 1.5s ease-out;
}

.panels {
  margin: 50px auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.panels .circle__bg,
.panels.panels--reversed .circle__bg {
  right: auto;
  left: 6%;
}

@media screen and (max-width: 1000px) {
  .panels .panels__main {
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }

  .panels .circle {
    width: 85%;
  }
}

@media screen and (max-width: 512px) {
  .panels .svg-stroke,
  .panels .svg-stroke-thin {
    stroke-width: 0.5vw;
  }
}

.panels__side {
  margin-bottom: 50px;
}

@media screen and (min-width: 1001px) {
  .panels {
    margin: 250px auto;
    flex-direction: row;
    justify-content: space-between;
  }

  .panels .circle__bg {
    right: -10%;
    left: auto;
  }

  .panels__main {
    width: 50%;
  }

  .panels__side {
    width: 50%;
    margin-bottom: 0;
    position: relative;
  }

  .panels--centred {
    align-items: center;
  }

  .panels.panels--reversed {
    flex-direction: row-reverse;
  }

  .panels.panels--reversed .circle__bg {
    right: auto;
    left: -10%;
  }
}

.project-promo__item {
  position: relative;
}

.project__header {
  width: 100%;
}

.project__header .illustration {
  position: relative;
}

@media screen and (min-width: 651px) {
  .project-promo {
    margin: 120px auto;
  }

  .project-promo__item {
    display: flex;
    padding-bottom: 30px;
  }

  .project-promo__item + .project-promo__item {
    margin-top: 50px;
  }

  .project-promo__item:not(:last-child):after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    border: none;
    background: var(--text);
    opacity: 0.275;
  }

  .project__details {
    width: 60%;
    margin-right: 0;
    margin-left: auto;
  }

  .project__header {
    width: 40%;
    margin-right: 20px;
  }

  .project__header .illustration {
    max-width: 400px;
    position: relative;
    margin: -20px auto 0;
  }
}

@media screen and (min-width: 650px) and (max-width: 900px) {
  .project__header {
    display: none;
  }

  .project__details {
    width: 100%;
  }
}

.dot-header {
  position: relative;
  width: 100%;
}

.dot-header__content {
  margin-left: 0;
}

@media screen and (min-width: 1001px) {
  .dot-header__content {
    max-width: 50%;
  }
}

.dot-header__dots .dots {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 1001px) {
  .dot-header__dots {
    width: 40%;
    height: 100%;
    position: absolute;
    right: -5%;
    top: 0;
  }
}

.reveal-img {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.article__content .reveal-img {
  margin: 40px auto;
}

.reveal-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transform: scale(1.1);
  transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.125s;
}

.no-js .reveal-img img,
.reveal-img.is-active img {
  opacity: 1;
  transform: none;
}

.reveal-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--fill);
  transition: transform 0.4s cubic-bezier(0.45, 0.85, 0.35, 0.85);
  backface-visibility: hidden;
  will-change: transform;
  transform: translateY(101%);
}

.reveal-img.is-active:after {
  transform: translateY(-101%);
  transition-delay: 0.2s;
}

.reveal-img.is-ready:after {
  transform: translateY(0);
}

.photo-grid {
  margin: 100px 0 50px;
  overflow: hidden;
}

.photo-grid__content img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 762px) {
  .photo-grid__content .col[style] {
    transform: none !important;
  }
}

@media screen and (min-width: 763px) {
  .photo-grid__content {
    display: flex;
    justify-content: space-between;
    margin: 0 -25px;
  }

  .photo-grid__content .col {
    width: 50%;
    padding: 0 25px;
  }

  .photo-grid__content .col:last-child {
    margin-top: -50px;
  }
}

.photo-grid__footer {
  text-align: center;
}

.photo {
  max-width: 100%;
  position: relative;
  margin-bottom: 25px;
}

@media screen and (min-width: 763px) {
  .photo {
    margin-bottom: 50px;
  }
}

.photo__img {
  display: block;
}

.photo__duo {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-out;
  backface-visibility: hidden;
}

.photo__duo:hover {
  opacity: 0;
}

@keyframes scrollBob {
  0%,
  10% {
    transform: none;
    opacity: 1;
    color: #919795;
  }

  1%,
  8% {
    transform: translateY(-2px);
    color: var(--fill);
  }

  4% {
    transform: translateY(3px);
    opacity: 1;
    color: #919795;
  }
}

.page-header {
  position: relative;
}

.page-header:after {
  content: "";
  transform-origin: bottom center;
  position: absolute;
  width: 1px;
  height: 25vh;
  left: 220px;
  top: 100%;
  background: var(--text);
  opacity: 0.45;
}

.page-header__inr {
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 33.33vh;
}

@media screen and (max-width: 513px) {
  .page-header__inr {
    align-items: flex-start;
    margin-top: 100px;
  }
}

.page-header__content {
  position: relative;
  width: 100%;
  min-height: 25vh;
  margin-bottom: 100px;
}

.thumbnail-generator .page-header__content:after {
  content: none;
}

.page-header__content:after {
  content: "";
  transform-origin: bottom center;
  position: absolute;
  width: 1px;
  height: 50vh;
  left: 220px;
  top: calc(100% + 140px);
  background: var(--text);
  opacity: 0.45;
  animation: vLineIn 1s ease-in-out 1.5s backwards;
}

@media screen and (max-width: 512px) {
  .page-header__content:after {
    top: calc(100% + 80px);
  }
}

.is-loading .page-header__content:after {
  animation-play-state: paused;
}

.page-header__lede {
  max-width: 900px;
}

.page-header__scroll {
  position: absolute;
  /* bottom: -120px; */
  /* left: 1220px;  */
  /* transform: translateX(-50%); */
  font-size: 1.2rem;
  color: #919795;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  text-transform: uppercase;
}

.page-header__scroll > span {
  animation: reveal 1s ease-out 1.5s backwards;
}

.is-loading .page-header__scroll > span {
  animation-play-state: paused;
}

.page-header__scroll > span span {
  margin: 0 1px;
  display: inline-block;
  animation: scrollBob 8s ease-in-out infinite;
}

.is-loading .page-header__scroll > span span {
  animation-play-state: paused;
}

.page-header__scroll > span span:first-child {
  animation-delay: 3.0725s;
}

.page-header__scroll > span span:nth-child(2) {
  animation-delay: 3.145s;
}

.page-header__scroll > span span:nth-child(3) {
  animation-delay: 3.2175s;
}

.page-header__scroll > span span:nth-child(4) {
  animation-delay: 3.29s;
}

.page-header__scroll > span span:nth-child(5) {
  animation-delay: 3.3625s;
}

.page-header__scroll > span span:nth-child(6) {
  animation-delay: 3.435s;
}

.page-header__scroll > span span:nth-child(7) {
  animation-delay: 3.5075s;
}

.page-header__scroll > span span:nth-child(8) {
  animation-delay: 3.58s;
}

.page-header__scroll > span span:nth-child(9) {
  animation-delay: 3.6525s;
}

@media screen and (max-width: 512px) {
  .page-header__scroll {
    bottom: -60px;
  }
}

@media screen and (max-width: 1000px) {
  .page-header:after,
  .page-header__content:after,
  .page-header__scroll {
    left: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .page-header--masthead:after {
    height: 50vh;
  }

  .page-header--masthead .page-header__inr {
    min-height: auto;
    margin-bottom: 60vh;
  }
}

@media screen and (max-width: 1000px) and (max-width: 513px) {
  .page-header--masthead .page-header__inr {
    align-items: center;
    margin-top: 0;
  }
}

@media screen and (max-width: 1000px) {
  .page-header--masthead .page-header__scroll {
    bottom: 20px;
  }
}

@media screen and (max-width: 512px) {
  .page-header--masthead .page-header__content {
    text-align: center;
  }

  .page-header--masthead .page-header__content br {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .page-header--masthead .page-header__content {
    min-height: auto;
    margin-bottom: 0;
    transform: none !important;
    text-align: center;
    padding: 10px 0 50px;
  }
}

@media screen and (min-width: 1201px) {
  .page-header--masthead .page-header__content {
    max-width: 600px;
  }
}

.page-header--masthead .page-header__lede,
.page-header--masthead .page-header__title {
  animation: reveal 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s backwards;
}

.is-loading .page-header--masthead .page-header__lede,
.is-loading .page-header--masthead .page-header__title {
  animation-play-state: paused;
}

.page-header--masthead .page-header__lede {
  animation-delay: 0.75s;
  font-size: 2rem;
  max-width: 500px;
  margin-top: 30px;
}

@media screen and (max-width: 512px) {
  .page-header--masthead .page-header__lede {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .page-header--masthead .page-header__lede {
    font-size: 2.4rem;
  }
}

.page-header--masthead .page-header__title {
  font-size: 3.8rem;
}

@media screen and (min-width: 513px) and (max-width: 1000px) {
  .page-header--masthead .page-header__title {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .page-header--masthead .page-header__title {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1201px) {
  .page-header--masthead .page-header__title {
    font-size: 8rem;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
}

@keyframes vLineIn {
  0% {
    transform: scaleY(0);
  }
}

@media screen and (min-width: 1001px) and (max-height: 600px) {
  .page-header__inr {
    min-height: 600px;
    height: 600px;
  }
}

.thumbnail-generator .page-header__content {
  margin-bottom: 0;
}

.circle {
  max-width: 400px;
  margin: 0 auto;
}

.circle__inr {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100%;
  animation: bakedAvatar 1.125s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s
    backwards;
  animation-play-state: paused;
}

.circle__inr svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.circle__inr.is-active,
.circle__inr.is-active .circle__bg:after,
.no-js .circle__inr,
.no-js .circle__inr .circle__bg:after {
  animation-play-state: running;
}

.circle__bg {
  top: 5%;
  width: 88%;
  height: 88%;
  transition: all 1.5s ease-out;
  background-repeat: no-repeat;
}

.circle__bg,
.circle__bg:after {
  position: absolute;
  border-radius: 100%;
}

.circle__bg:after {
  content: "";
  background: inherit;
  background-repeat: repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: circleIn 0.5s cubic-bezier(1, 1.6, 0, 1.66) backwards;
  animation-play-state: paused;
}

@keyframes circleIn {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.iconwrap,
.illustration__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.code {
  overflow: hidden;
  width: 21%;
  height: 14%;
  top: 57%;
  left: 19%;
}

.code,
.code__inr {
  position: absolute;
}

.code__inr {
  height: 200vh;
  width: 100%;
  top: 0;
  left: 0;
  background-size: 100% auto;
  background-repeat: repeat-y;
}

.pointer {
  backface-visibility: hidden;
  position: absolute;
  height: 27%;
  width: 46%;
  top: 53%;
  left: 11.5%;
  transition: transform 0.125s ease-out;
}

.pointer svg polygon {
  stroke: var(--line);
  fill: var(--line);
}

@keyframes bob {
  0% {
    transform: translateY(-2.5%);
  }
}

@keyframes bob2 {
  to {
    transform: translateY(-2.5%);
  }
}

.iconwrap--a {
  animation: bob 4s ease-in-out infinite alternate;
}

.iconwrap--b {
  animation: bob2 5s ease-in-out infinite alternate;
}

.author {
  position: relative;
  margin: 50px auto;
  padding-top: 50px;
}

.author:after {
  content: "";
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  left: 0;
  background: var(--text);
  opacity: 0.275;
}

@media screen and (min-width: 680px) {
  .author {
    margin: 100px auto 150px;
    padding-top: 100px;
  }
}

.author__inr {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media screen and (min-width: 680px) {
  .author__inr {
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.author__bio {
  max-width: 400px;
}

@media screen and (min-width: 680px) {
  .author__bio {
    max-width: none;
    width: 66.6666%;
  }
}

.author__portrait {
  max-width: 200px;
  width: 100%;
  margin: 0 auto 30px;
}

@media screen and (min-width: 680px) {
  .author__portrait {
    margin: 0;
    max-width: none;
    padding-right: 50px;
    width: 33.3333%;
  }
}

.author__cta {
  margin: 0 auto;
}
